<script lang="ts" setup>
const auth = useAuth()
useHead({
  title: 'AMM²',
})

const isListed = computed(() => auth.project?.tokenListed)
</script>

<template>
  <div>
    <NuxtLayout name="dashboard">
      <template #header>
        <div class="flex gap-3 items-center">
          <div>
            <!-- Vite is doing something weird with this string literal -->
            <img
              :src="String('/icons/market-maker-monitoring.svg')"
              alt="Market Maker Monitoring"
            >
          </div>
          <div class="text-xl font-semibold flex-shrink-0">
            AMM
            <sup>2</sup>
          </div>
          <UiPill v-if="!isListed" class="bg-forgd-bgd-400">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0007 12.5V14.1667M5.00065 17.5H15.0007C15.4427 17.5 15.8666 17.3244 16.1792 17.0118C16.4917 16.6993 16.6673 16.2754 16.6673 15.8333V10.8333C16.6673 10.3913 16.4917 9.96738 16.1792 9.65482C15.8666 9.34226 15.4427 9.16667 15.0007 9.16667H5.00065C4.55862 9.16667 4.1347 9.34226 3.82214 9.65482C3.50958 9.96738 3.33398 10.3913 3.33398 10.8333V15.8333C3.33398 16.2754 3.50958 16.6993 3.82214 17.0118C4.1347 17.3244 4.55862 17.5 5.00065 17.5ZM13.334 9.16667V5.83333C13.334 4.94928 12.9828 4.10143 12.3577 3.47631C11.7326 2.85119 10.8847 2.5 10.0007 2.5C9.1166 2.5 8.26875 2.85119 7.64363 3.47631C7.01851 4.10143 6.66732 4.94928 6.66732 5.83333V9.16667H13.334Z"
                stroke="#1A54C4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div>Available after TGE</div>
          </UiPill>
        </div>
      </template>
      <div>
        <div v-if="$route.meta.intro" class="border-b border-forgd-gray-300 text-base font-normal text-forgd-gray-600">
          <div class="max-w-2xl mb-5 ">
            Support {{ auth.ticker }} with automated market making strategies for all centralized &amp; decentralized exchanges from a single dashboard. Deploy algorithms for liquidity provision &amp; arbitrage strategies. Measure impact across depth, spreads, volume, and other KPI’s. <a class="text-base text-forgd-primary-300 font-semibold" href="#">Learn more.</a>
          </div>
        </div>
        <div>
          <slot />
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
